<template>
  <!-- 交卷中，请稍等 -->
  <a-spin
    wrapperClassName="paper-spin"
    :spinning="loading"
    :tip="$t('exam.submit_paper_wait') + ' ' + latency.s"
  >
    <section class="head">
      <h1>{{ projectData.examName }}</h1>
      <p>
        <span
          >{{ $t("practice.total_score") }}：{{ projectData.totalScore }}</span
        >
        <!-- 总分： -->
        <span>{{ $t("practice.pass_line") }}：{{ projectData.passScore }}</span>
        <!-- 及格线： -->
        <!-- <span>
          剩余次数：
          <template v-if="projectData.examTimes > 1">
            {{ projectData.examTimes - projectData.myTimes }}
          </template>
          <template v-else-if="projectData.examTimes == -1">0</template>
          <template v-else-if="projectData.examTimes == -2">不限</template>
        </span> -->
        <span>
          {{ $t("LB_Exam_CutScreenNum") }}：
          <!-- 切屏次数： -->
          <template v-if="projectData.switchNum == -2">{{
            $t("exam.rule_unlimited")
          }}</template>
          <!-- 无限次 -->
          <template v-else-if="projectData.switchNum == -1">{{
            $t("exam.cut_screen_tip")
          }}</template>
          <!-- 不允许切屏 -->
          <template v-else-if="projectData.switchNum > 0">
            {{ paperData.useSwitchNum || 0 }}/{{ projectData.switchNum }}
          </template>
        </span>
        <span>
          {{ $t("exam.total_duration") }}：
          <!-- 总时长： -->
          {{
            projectData.limitTime != 0
              ? projectData.limitTime / 60 + $t("CM_Minute")
              : $t("CM_Unlimited")
          }}
          <!-- 不限 -->
        </span>
        <span>{{ $t("exam.auto_scoring_tip") }}</span>
        <!-- 若有主观题，系统不支持自动判分 -->
      </p>
    </section>

    <section class="main">
      <div class="content">
        <div class="water-marks" v-if="openViewWatermark">
          <div class="item" v-for="i in 200" :key="i">
            <OpenData type="userName" :openid="userInfo.realName" />({{
              userInfo.account
            }})
            <OpenData type="departmentName" :openid="userInfo.departmentName" />
          </div>
        </div>
        <div
          class="questions"
          :class="!(platformConfig.allowCopy == 1) && `exam-no-select`"
        >
          <div
            class="type-path"
            v-for="(path, index) in quesObj.values()"
            :key="index"
          >
            <template v-if="path.data.length && path.type !== 8">
              <div class="headline">
                <span class="classify">
                  {{ toChinesNum(index + 1) }}、{{ path.name }}
                </span>
                <span class="score">
                  （
                  {{
                    $t("exam.case_title", [path.data.length, path.totalScore])
                  }}
                  ）
                  <!-- 本大题共{0}小题，共{1}分 -->
                </span>
              </div>
              <div
                class="questions-item"
                v-for="(item, queIndex) in path.data"
                :key="item.questionId"
                :id="'que_' + item.questionId"
              >
                <div class="questions-item-head">
                  <div class="type-title">
                    {{ queIndex + 1 }}.{{ path.name }}
                    <span>（{{ $t("exam.nth_score", [item.score]) }}）</span>
                    <!-- 分 -->
                  </div>
                  <div class="questions-item-more">
                    <!-- <span class="tips" v-if="paperData.openExam == 1" @click="viewTips(item)"><BulbOutlined />查看提示</span> -->
                    <span
                      class="mask"
                      @click="item.isMask = !item.isMask"
                      :title="$t('LB_Tagged')"
                    >
                      <!-- 标记 -->
                      <FlagOutlined class="gray" v-show="!item.isMask" />
                      <FlagFilled class="highlight" v-show="item.isMask" />
                    </span>
                  </div>
                </div>
                <template v-if="path.type === 1 || path.type === 3">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div>
                    <a-radio-group v-model:value="item.userAnswer">
                      <a-radio
                        @change="radioChange($event, item)"
                        class="opt-radio"
                        v-model:value="opt.id"
                        v-for="(opt, optIndex) in item.options"
                        :key="opt.id"
                      >
                        <div class="opt-item">
                          <div class="letter">{{ letterOpts[optIndex] }}</div>
                          <div class="info">
                            <span class="text">{{ opt.title }}</span>
                            <exam-media
                              v-if="opt.img"
                              type="option"
                              :mediaList="[opt.img]"
                            />
                          </div>
                        </div>
                      </a-radio>
                    </a-radio-group>
                  </div>
                </template>
                <template v-else-if="path.type === 2">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div>
                    <a-checkbox-group v-model:value="item.userAnswer">
                      <a-checkbox
                        class="opt-checkbox"
                        :value="opt.id"
                        v-for="(opt, optIndex) in item.options"
                        :key="opt.id"
                        @change="checkboxChange($event, item, opt)"
                      >
                        <div class="opt-item">
                          <div class="letter">{{ letterOpts[optIndex] }}</div>
                          <div class="info">
                            <span class="text">{{ opt.title }}</span>
                            <exam-media
                              v-if="opt.img"
                              type="option"
                              :mediaList="[opt.img]"
                            />
                          </div>
                        </div>
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </template>
                <template v-else-if="path.type === 4">
                  <p class="title">
                    <template
                      v-for="(opt, optIndex) in item.options"
                      :key="opt.id"
                    >
                      <span>{{ item.titles[optIndex] }}</span>
                      <!-- <div class="opt-textarea-box">
                        <div class="resize-h">{{ opt.title }}</div>
                        <textarea
                          class="opt-textarea"
                          :onpaste="
                            'return ' +
                            (!(platformConfig.allowCopy == 1)
                              ? 'false;'
                              : 'true;')
                          "
                          v-model="opt.title"
                          @input="gapFilling(item, opt)"
                        ></textarea>
                      </div> -->
                      <a-tooltip trigger="focus">
                        <template #title>{{ opt.title }}</template>
                        <input
                          class="opt-input"
                          :onpaste="
                            'return ' +
                            (!(platformConfig.allowCopy == 1)
                              ? 'false;'
                              : 'true;')
                          "
                          v-model="opt.title"
                          @input="gapFilling(item, opt)"
                        />
                      </a-tooltip>
                    </template>
                    <span v-if="item.titles.length > item.options.length">
                      {{ item.titles[item.titles.length - 1] }}
                    </span>
                  </p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                </template>
                <template v-else-if="path.type === 5">
                  <p class="title">{{ item.title }}</p>
                  <template v-if="item.titimgs && item.titimgs.length">
                    <exam-media :mediaList="item.titimgs" />
                  </template>
                  <div
                    :onpaste="
                      'return ' +
                      (!(platformConfig.allowCopy == 1) ? 'false;' : 'true;')
                    "
                  >
                    <textarea
                      class="opt-textarea"
                      v-model="item.options[0].title"
                      @input="qAndA(item, item.options[0])"
                    ></textarea>
                  </div>
                  <div
                    class="opt-pics"
                    v-viewer="{ modal: true, title: false }"
                    v-if="!noUploadImg"
                  >
                    <div
                      class="opt-pics-item ed"
                      v-for="(itemPic, itemPicIndex) in item.answerImg2"
                      :key="itemPicIndex"
                    >
                      <img :src="itemPic" alt="picture" />
                      <div
                        class="opt-pics-item-close"
                        @click.stop="delPic(item, itemPicIndex)"
                      >
                        <CloseOutlined class="icon" />
                      </div>
                    </div>
                    <div class="opt-pics-item add">
                      <a-upload
                        :action="uploadUrl + 'study/upload/file'"
                        :headers="getHeader()"
                        :multiple="true"
                        accept=".png,.jpg,.jpeg"
                        :showUploadList="false"
                        :beforeUpload="handleBeforeUpload"
                        @change="(info) => handleUploadChange(info, item)"
                      >
                        <div class="up-input">
                          <plus-outlined style="font-size: 18px" />
                          <div class="up-input-text">
                            {{ $t("upload.upload_img") }}
                          </div>
                          <!-- 上传图片 -->
                        </div>
                      </a-upload>
                    </div>
                  </div>
                </template>
                <template v-if="paperData.openExam == 1">
                  <div
                    class="right-answer"
                    v-if="
                      item.questionType != 5 ||
                      (item.questionType == 5 && item.copyStrAnswer) ||
                      item.analysis ||
                      item.analysisimgs
                    "
                  >
                    <div class="answer" v-if="item.questionType != 5">
                      {{ $t("exam.right_answer") }}:
                      <!-- 正确答案: -->
                      <template v-if="item.questionType == 4">
                        <span
                          v-for="(oItem, oIndex) in item.copyStrAnswer"
                          :key="oIndex"
                        >
                          {{ oIndex + 1 }}、<span class="mr-10">{{
                            oItem.replace("$", " / ")
                          }}</span>
                        </span>
                      </template>
                      <template v-else>
                        <template v-for="(oItem, oIndex) in item.options">
                          <span
                            class="mr-10"
                            :key="oItem.id"
                            v-if="item.strAnswer.includes(oItem.id)"
                            >{{ letterOpts[oIndex] }}</span
                          >
                        </template>
                      </template>
                    </div>
                    <div
                      class="answer"
                      v-if="item.analysis || item.analysisimgs"
                    >
                      {{ $t("LB_Exam_QuestionAnalysis") }}:
                      <span v-html="item.analysis"></span>
                      <!-- 答题解析: -->
                      <analysis-media
                        v-if="item.analysisimgs"
                        :data="item.analysisimgs"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="path.data.length && path.type === 8">
              <div v-for="(caseItem, caseIndex) in path.data" :Key="caseIndex">
                <div class="headline">
                  <span class="classify">
                    {{ toChinesNum(index + caseIndex + 1) }}、{{ path.name }}
                  </span>
                  <span class="score">
                    （
                    {{
                      $t("exam.case_title", [
                        caseItem.list.length,
                        caseItem.score,
                      ])
                    }}
                    ）
                    <!-- 本大题共{0}小题，共{1}分 -->
                  </span>
                </div>
                <div class="case-box">
                  <div class="case-box-head">
                    <p class="title">{{ caseItem.title }}</p>
                    <template
                      v-if="caseItem.titimgs && caseItem.titimgs.length"
                    >
                      <exam-media :mediaList="caseItem.titimgs" />
                    </template>
                  </div>
                  <div
                    class="questions-item"
                    v-for="(item2, queIndex2) in caseItem.list"
                    :key="item2.questionId"
                    :id="'que_' + item2.questionId"
                  >
                    <div class="questions-item-head">
                      <div class="type-title">
                        {{ queIndex2 + 1 }}.{{
                          questionTypeEnum[item2.questionType]
                        }}
                        <span
                          >（{{ $t("exam.nth_score", [item2.score]) }}）</span
                        >
                        <!-- 分） -->
                      </div>
                      <div class="questions-item-more">
                        <!-- <span class="tips" v-if="paperData.openExam == 1" @click="viewTips(item)"><BulbOutlined />查看提示</span> -->
                        <span
                          class="mask"
                          @click="item2.isMask = !item2.isMask"
                          :title="$t('LB_Tagged')"
                        >
                          <!-- 标记 -->
                          <FlagOutlined class="gray" v-show="!item2.isMask" />
                          <FlagFilled class="highlight" v-show="item2.isMask" />
                        </span>
                      </div>
                    </div>
                    <template
                      v-if="
                        item2.questionType === 1 || item2.questionType === 3
                      "
                    >
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div>
                        <a-radio-group v-model:value="item2.userAnswer">
                          <a-radio
                            @change="radioChange($event, item2)"
                            class="opt-radio"
                            v-model:value="opt.id"
                            v-for="(opt, optIndex) in item2.options"
                            :key="opt.id"
                          >
                            <div class="opt-item">
                              <div class="letter">
                                {{ letterOpts[optIndex] }}
                              </div>
                              <div class="info">
                                <span class="text">{{ opt.title }}</span>
                                <exam-media
                                  v-if="opt.img"
                                  type="option"
                                  :mediaList="[opt.img]"
                                />
                              </div>
                            </div>
                          </a-radio>
                        </a-radio-group>
                      </div>
                    </template>
                    <template v-else-if="item2.questionType === 2">
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div>
                        <a-checkbox-group v-model:value="item2.userAnswer">
                          <a-checkbox
                            class="opt-checkbox"
                            :value="opt.id"
                            v-for="(opt, optIndex) in item2.options"
                            :key="opt.id"
                            @change="checkboxChange($event, item2, opt)"
                          >
                            <div class="opt-item">
                              <div class="letter">
                                {{ letterOpts[optIndex] }}
                              </div>
                              <div class="info">
                                <span class="text">{{ opt.title }}</span>
                                <exam-media
                                  v-if="opt.img"
                                  type="option"
                                  :mediaList="[opt.img]"
                                />
                              </div>
                            </div>
                          </a-checkbox>
                        </a-checkbox-group>
                      </div>
                    </template>
                    <template v-else-if="item2.questionType === 4">
                      <p class="title">
                        <template
                          v-for="(opt, optIndex) in item2.options"
                          :key="opt.id"
                        >
                          <span>{{ item2.titles[optIndex] }}</span>
                          <!-- <div class="opt-textarea-box">
                          <div class="resize-h">{{ opt.title }}</div>
                          <textarea
                            class="opt-textarea"
                            :onpaste="
                              'return ' +
                              (!(platformConfig.allowCopy == 1)
                                ? 'false;'
                                : 'true;')
                            "
                            v-model="opt.title"
                            @input="gapFilling(item, opt)"
                          ></textarea>
                        </div> -->
                          <a-tooltip trigger="focus">
                            <template #title>{{ opt.title }}</template>
                            <input
                              class="opt-input"
                              :onpaste="
                                'return ' +
                                (!(platformConfig.allowCopy == 1)
                                  ? 'false;'
                                  : 'true;')
                              "
                              v-model="opt.title"
                              @input="gapFilling(item2, opt)"
                            />
                          </a-tooltip>
                        </template>
                        <span v-if="item2.titles.length > item2.options.length">
                          {{ item2.titles[item2.titles.length - 1] }}
                        </span>
                      </p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                    </template>
                    <template v-else-if="item2.questionType === 5">
                      <p class="title">{{ item2.title }}</p>
                      <template v-if="item2.titimgs && item2.titimgs.length">
                        <exam-media :mediaList="item2.titimgs" />
                      </template>
                      <div
                        :onpaste="
                          'return ' +
                          (!(platformConfig.allowCopy == 1)
                            ? 'false;'
                            : 'true;')
                        "
                      >
                        <textarea
                          class="opt-textarea"
                          v-model="item2.options[0].title"
                          @input="qAndA(item2, item2.options[0])"
                        ></textarea>
                      </div>
                      <div
                        class="opt-pics"
                        v-viewer="{ modal: true, title: false }"
                        v-if="!noUploadImg"
                      >
                        <div
                          class="opt-pics-item ed"
                          v-for="(itemPic, itemPicIndex) in item2.answerImg2"
                          :key="itemPicIndex"
                        >
                          <img :src="itemPic" alt="picture" />
                          <div
                            class="opt-pics-item-close"
                            @click.stop="delPic(item2, itemPicIndex)"
                          >
                            <CloseOutlined class="icon" />
                          </div>
                        </div>
                        <div class="opt-pics-item add">
                          <a-upload
                            :action="uploadUrl + 'study/upload/file'"
                            :headers="getHeader()"
                            :multiple="true"
                            accept=".png,.jpg,.jpeg"
                            :showUploadList="false"
                            :beforeUpload="handleBeforeUpload"
                            @change="(info) => handleUploadChange(info, item2)"
                          >
                            <div class="up-input">
                              <plus-outlined style="font-size: 18px" />
                              <div class="up-input-text">
                                {{ $t("upload.upload_img") }}
                              </div>
                              <!-- 上传图片 -->
                            </div>
                          </a-upload>
                        </div>
                      </div>
                    </template>
                    <template v-if="paperData.openExam == 1">
                      <div
                        class="right-answer"
                        v-if="
                          item2.questionType != 5 ||
                          (item2.questionType == 5 && item2.copyStrAnswer) ||
                          item2.analysis ||
                          item2.analysisimgs
                        "
                      >
                        <div class="answer" v-if="item2.questionType != 5">
                          {{ $t("exam.right_answer") }}:
                          <!-- 正确答案: -->
                          <template v-if="item2.questionType == 4">
                            <span
                              v-for="(oItem, oIndex) in item2.copyStrAnswer"
                              :key="oIndex"
                            >
                              {{ oIndex + 1 }}、<span class="mr-10">{{
                                oItem.replace("$", " / ")
                              }}</span>
                            </span>
                          </template>
                          <template v-else>
                            <template v-for="(oItem, oIndex) in item2.options">
                              <span
                                class="mr-10"
                                :key="oItem.id"
                                v-if="item2.strAnswer.includes(oItem.id)"
                                >{{ letterOpts[oIndex] }}</span
                              >
                            </template>
                          </template>
                        </div>
                        <div
                          class="answer"
                          v-if="item2.analysis || item2.analysisimgs"
                        >
                          {{ $t("LB_Exam_QuestionAnalysis") }}:
                          <span v-html="item2.analysis"></span>
                          <!-- 答题解析 -->
                          <analysis-media
                            v-if="item2.analysisimgs"
                            :data="item2.analysisimgs"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="sidebar">
          <div class="sidebar-content">
            <div class="time">
              <h5>{{ $t("project.time_left") }}</h5>
              <!-- 剩余时间 -->
              <div class="down">{{ time.h }}:{{ time.m }}:{{ time.s }}</div>
            </div>
            <div class="sheet">
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="progress" v-if="paperData.questions">
                <div class="left">
                  <a-progress
                    :strokeWidth="12"
                    strokeColor="#447DFF"
                    trailColor="#DDDDDD"
                    :showInfo="false"
                    :percent="(finished / qusetionTotal) * 100"
                  />
                </div>
                <span class="right"> {{ finished }}/{{ qusetionTotal }} </span>
              </div>
              <div class="label">
                <div class="item"><span></span>{{ $t("CM_Answer") }}</div>
                <!-- 已答 -->
                <div class="item"><span></span>{{ $t("CM_NoAnswer") }}</div>
                <!-- 未答 -->
                <div class="item"><span></span>{{ $t("LB_Tagged") }}</div>
                <!-- 标记 -->
              </div>
              <div class="sheet-list">
                <div
                  class="type-path"
                  v-for="(path, index) in quesObj.values()"
                  :key="index"
                >
                  <template v-if="path.data.length && path.type !== 8">
                    <div class="classify">
                      {{ toChinesNum(index + 1) }}、{{ path.name }}
                    </div>
                    <div class="list clearfix">
                      <template v-if="path.type === 1">
                        <div
                          class="item answer-chunk"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            checked: item.userAnswer !== '',
                            isMask: item.isMask,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 2">
                        <div
                          class="item answer-chunk"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            checked: item.userAnswer.length,
                            isMask: item.isMask,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 3">
                        <div
                          class="item answer-chunk"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            checked: item.userAnswer !== '',
                            isMask: item.isMask,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 4">
                        <div
                          class="item answer-chunk"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            checked: item.userAnswer.length,
                            isMask: item.isMask,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                      <template v-else-if="path.type === 5">
                        <div
                          class="item answer-chunk"
                          v-for="(item, queIndex) in path.data"
                          :key="item.questionId"
                          :class="{
                            checked:
                              item.userAnswer.length || item.answerImg.length,
                            isMask: item.isMask,
                          }"
                          @click="scrollTo(item.questionId)"
                        >
                          {{ queIndex + 1 }}
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-if="path.data.length && path.type === 8">
                    <div
                      v-for="(caseItem, caseIndex) in path.data"
                      :Key="caseIndex"
                    >
                      <div class="classify">
                        {{ toChinesNum(index + caseIndex + 1) }}、{{
                          path.name
                        }}
                      </div>
                      <div class="list clearfix">
                        <template
                          v-for="(item, queIndex) in caseItem.list"
                          :key="item.questionId"
                        >
                          <div
                            class="item answer-chunk"
                            v-if="item.questionType === 1"
                            :class="{
                              checked: item.userAnswer !== '',
                              isMask: item.isMask,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item answer-chunk"
                            v-if="item.questionType === 2"
                            :class="{
                              checked: item.userAnswer.length,
                              isMask: item.isMask,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item answer-chunk"
                            v-if="item.questionType === 3"
                            :class="{
                              checked: item.userAnswer !== '',
                              isMask: item.isMask,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item answer-chunk"
                            v-if="item.questionType === 4"
                            :class="{
                              checked: item.userAnswer.length,
                              isMask: item.isMask,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                          <div
                            class="item answer-chunk"
                            v-if="item.questionType === 5"
                            :class="{
                              checked:
                                item.userAnswer.length || item.answerImg.length,
                              isMask: item.isMask,
                            }"
                            @click="scrollTo(item.questionId)"
                          >
                            {{ queIndex + 1 }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="btn">
                <a-button
                  type="primary"
                  danger
                  :loading="stagedLoading || loading"
                  @click="handleStaged"
                  >{{ $t("exam.save") }}</a-button
                >
                <!-- 暂存 -->
                <a-button
                  type="primary"
                  :loading="loading"
                  @click="submit(2)"
                  >{{ $t("exam.submit") }}</a-button
                >
                <!-- 交卷 -->
              </div>
            </div>
          </div>
        </div>
        <!-- <a-modal v-model:visible="showTips" title="提示" :footer="null" wrapClassName="tips-modal">
        <p class="answer" v-if="currentQuestion.questionType != 5">
          正确答案：
          <template v-if="currentQuestion.questionType == 4">
            <span v-for="(oItem, oIndex) in currentQuestion.correctAnswer" :key="oIndex">
              {{oIndex + 1}}、<span class="mr-10">{{oItem.replace('$', ' / ')}}</span>
            </span>
          </template>
          <template v-else>
            <template v-for="(oItem, oIndex) in currentQuestion.options">
              <span class="mr-10" :key="oItem.id" v-if="currentQuestion.correctAnswer.includes(oItem.id)">{{letterOpts[oIndex]}}</span>
            </template>
          </template>
        </p>
        <p class="analysis" v-if="currentQuestion.analysis" v-html="currentQuestion.analysis"></p>
      </a-modal> -->
      </div>
    </section>
  </a-spin>
  <!-- 签名 -->
  <signatureModal ref="signatureModalRef" @complete="completeSignature" />
  <!-- 人脸识别 -->
  <faceMatch
    ref="faceMatchRef"
    :id="paperData.submitId"
    :errAutoBack="false"
    :taskType="1"
    @on-result="handleFace"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import { examAutoSave, examStart, examSubmit, examSwitch } from "@/api/exam";
import { plusDetail } from "@/api/course";
import { getTaskDetail } from "@/api/project";
import { getRetakeDetail, stopExam } from "@/api/exam";
import { getCustomerConfig } from "@/api/mine";
import { letterOpts, questionTypeEnum, resourceType } from "@/utils/business";
import ls from "@/utils/local-storage";
import {
  fullscreen,
  onHide,
  onShow,
  cancelFullscreen,
  uploadUrl,
  getHeader,
  getCdnUrl,
  toChinesNum,
} from "@/utils/tools";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import {
  computed,
  createVNode,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  ref,
  reactive,
  onUnmounted,
  nextTick,
  toRefs,
} from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";
import signatureModal from "./signatureModal.vue";
import examMedia from "./exam-media.vue";
import analysisMedia from "./analysis-media.vue";
import faceMatch from "@/components/faceCapture/match.vue";
import OpenData from "@/components/OpenData.vue";
import { currentHost } from "@/utils/request.js";

export default {
  components: {
    signatureModal,
    examMedia,
    analysisMedia,
    faceMatch,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const queryData = route.query;
    const taskId = parseInt(queryData.taskId || 0);
    const detailId = parseInt(queryData.detailId || 0);
    const courseId = parseInt(queryData.courseId || 0);
    const reexamId = parseInt(queryData.reexamId || 0);
    const planId = parseInt(queryData.planId || 0);
    const isIdp = parseInt(queryData.isIdp || 0);
    const source = queryData.source;
    const projectData = ref({});
    const paperData = ref({});
    const showTips = ref(false);
    const currentQuestion = ref({});
    const qusetionTotal = ref(0);
    const store = useStore();
    const platformConfig = computed(() => store.getters.platformConfig);
    const state = reactive({
      courseApi: "",
      params: {
        taskId: taskId,
        detailId: detailId,
      },
      switchTimeStr: "",
      faceSet: null,
      lastCount: 1, // 剩余人脸识别次数
      faceAverageTime: 0, // 平均时长
      examRemainTime: 0, // 考试剩余时长
      openViewWatermark: false,
      userInfo: ls.get("userInfo"),
    });
    const noUploadImg = ref(true);
    const visProp = onHide();
    const canSwitchSubmit = ref(true);
    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isDingding = /dingtalk/i.test(ua); // 是否钉钉

    getCustomerConfig({ site: currentHost }).then((res) => {
      let d = res.data || [];
      d.forEach((item) => {
        if (item.configKey == "viewWatermark") {
          if (item.configValue.includes("3")) state.openViewWatermark = true;
        }
      });
    });

    const getDetail = async () => {
      let method = getTaskDetail;
      if (queryData.taskType == 23) {
        method = getRetakeDetail;
      }
      let res = null;
      if (courseId) {
        state.courseApi = "course/";
        state.params = {
          taskId: taskId,
          courseId: courseId,
          coursePlusId: detailId,
        };
        // 课程考试
        res = await plusDetail({ courseId: courseId, taskId: taskId });
      } else {
        res = await method({ id: taskId, did: detailId, reexamId: reexamId });
      }
      if (res.ret === 0) {
        projectData.value = res.data.exam;
        if (
          route.query.switchNum != -2 ||
          projectData.value.switchTime !== -2
        ) {
          if (visProp) {
            let evtname =
              visProp.replace(/[H|h]idden/, "") + "visibilitychange";
            document.addEventListener(evtname, screenVisibility);
          }
          window.addEventListener("blur", windowBlur);
          window.addEventListener("focus", windowFocus);
          window.addEventListener("beforeunload", windowBeforeunload);
          window.addEventListener("unload", windowUnload);
        } else {
          noUploadImg.value = false;
        }
      }
    };
    getDetail();

    onMounted(() => {
      if (!isDingding) {
        fullscreen(document.querySelector("body"));
      }
      StartExam();
    });

    const viewTips = (item) => {
      showTips.value = true;
      currentQuestion.value = JSON.parse(JSON.stringify(item));
      currentQuestion.value.correctAnswer = currentQuestion.value.strAnswer;
      if (currentQuestion.value.questionType == 4) {
        currentQuestion.value.correctAnswer = JSON.parse(
          currentQuestion.value.correctAnswer
        );
      }
    };

    let isLocalData = false;
    let isRandom = false;
    const StartExam = (data) => {
      examStart(
        {
          ...state.params,
          face: "",
          reexamId: reexamId,
          planId: planId,
        },
        state.courseApi
      ).then((res) => {
        if (res.ret === 0) {
          if (!ls.get(res.data.submitId)) {
            ls.set(res.data.submitId, res.data);
            paperData.value = res.data;
          } else {
            isLocalData = true;
            paperData.value = ls.get(res.data.submitId);
            paperData.value.remainTime = res.data.remainTime;
          }
          if (courseId) {
            paperData.value.taskId = taskId;
          }
          isNormal = false;
          isRandom = paperData.value?.optionRandom == 1;
          renderQues(paperData.value.questions);
          toFinish();
          let cutScreenTip = "";
          if (
            (route.query.switchNum != -2 &&
              res.data.remain == 0 &&
              res.data.useTime) ||
            (res.data.switchTime === 0 && res.data.useTime)
          ) {
            submit(3);
          } else if (route.query.switchNum == -1 || res.data.switchTime === 0) {
            // 请注意，本次考试不允许切屏
            cutScreenTip = $t("exam.doexam_cut_screen_tip");
          } else if (route.query.switchNum > 0) {
            state.switchTimeStr =
              res.data.switchTime == -2
                ? $t("exam.infinitely")
                : $t("exam.nth_s", [res.data.switchTime]);
            paperData.value.useSwitchNum =
              route.query.switchNum - res.data.remain;
            // 请注意，本次考试切屏次数还剩 次，切屏时间
            cutScreenTip = $t("exam.doexam_cut_screen_tip_2", [
              res.data.remain,
              state.switchTimeStr,
            ]);
          } else if (res.data.switchTime > 0) {
            // 请注意，考试切屏时间为
            cutScreenTip = $t(
              "exam.PleaseNoteThatTheExamScreenCutTimeIsNthSeconds",
              [res.data.switchTime]
            );
          }
          cutScreenTip &&
            Modal.warning({
              title: () => cutScreenTip,
              maskClosable: true,
            });
          // 人脸识别
          const faceRes = ls.get("examFaceStartRes");
          if (faceRes) {
            nextTick(() => {
              faceMatchRef.value.submitResult(faceRes);
              ls.remove("examFaceStartRes");
            }, 500);
          }
          if (res.data.isopenFace) {
            state.lastCount = res.data.openFacecount;
            state.faceSet = res.data.openFaceverify
              ? JSON.parse(res.data.openFaceverify)
              : "";
            setLearningFace();
          }
        } else {
          isNormal = true;
          if (source === "map") {
            if (isDingding) {
              location.href = location.origin + `/map/detail?id=${taskId}`;
            } else {
              router.replace({
                path: "/map/detail",
                query: {
                  id: taskId,
                },
              });
            }
          } else {
            if (isDingding) {
              const url =
                queryData.taskType == 23
                  ? `&reexamId=${reexamId}&taskType=23`
                  : "";
              if (courseId) {
                location.href =
                  location.origin +
                  `/exam/detail?courseId=${courseId}&taskId=${taskId}&coursePlusId=${detailId}&isIdp=${isIdp}` +
                  url;
              } else {
                location.href =
                  location.origin +
                  `/exam/detail?id=${taskId}&did=${detailId}&isIdp=${isIdp}` +
                  url;
              }
            } else {
              let query = {
                id: taskId,
                did: detailId,
                isIdp: isIdp,
              };
              if (courseId) {
                query = {
                  ...state.params,
                };
              }
              if (queryData.taskType == 23) {
                query.reexamId = reexamId;
                query.taskType = queryData.taskType;
              }
              router.replace({
                path: "/exam/detail",
                query: query,
              });
            }
          }
        }
      });
    };

    // 数组打乱顺序
    const shuffle = (array) => {
      let m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const caseRender = (list) => {
      list.forEach((item, index) => {
        item.answers = item.answers || [];
        item.options = item.options || [];
        if (item.questionType === 1 || item.questionType === 3) {
          qusetionTotal.value++;
          item.userAnswer =
            item.answers && item.answers.length ? item.answers[0] : "";
        } else if (item.questionType === 2) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
        } else if (item.questionType === 4) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          item.titles = item.title.split("＿");
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          if (isLocalData) {
            item.answerImg = item.answerImg || [];
            item.answerImg2 = item.answerImg2 || [];
          } else {
            item.answerImg = item.answerImg || [];
            item.answerImg2 = JSON.parse(JSON.stringify(item.answerImg));
          }
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
        }
        // 开卷考试处理
        if (
          paperData.value.openExam == 1 &&
          (item.questionType == 4 || item.questionType == 5)
        ) {
          item.copyStrAnswer = JSON.parse(item.strAnswer);
        }
        // 选项是否打乱
        if (
          item.questionType == 1 ||
          item.questionType == 2 ||
          item.questionType == 3
        ) {
          isRandom && (item.options = shuffle(item.options));
        }
      });
    };

    // 30s自动提交
    let autoTimer = null;
    // 数据处理
    const quesObj = ref(new Map());
    Object.keys(questionTypeEnum).forEach((key) => {
      quesObj.value.set(Number(key), {
        name: questionTypeEnum[key],
        type: Number(key),
        data: [],
      });
    });
    const renderQues = (list) => {
      list.forEach((item, index) => {
        item.answers = item.answers || [];
        item.options = item.options || [];
        if (item.questionType === 1 || item.questionType === 3) {
          qusetionTotal.value++;
          item.userAnswer =
            item.answers && item.answers.length ? item.answers[0] : "";
        } else if (item.questionType === 2) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
        } else if (item.questionType === 4) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          item.titles = item.title.split("＿");
          if (!item.options.length) {
            for (var i = 0; i < item.titles.length - 1; i++) {
              item.options.push({ id: i, title: "", isRight: false });
            }
          }
        } else if (item.questionType === 5) {
          qusetionTotal.value++;
          item.userAnswer = item.answers;
          if (isLocalData) {
            item.answerImg = item.answerImg || [];
            item.answerImg2 = item.answerImg2 || [];
          } else {
            item.answerImg = item.answerImg || [];
            item.answerImg2 = JSON.parse(JSON.stringify(item.answerImg));
          }
          if (!item.options.length) {
            item.options = [{ id: 0, title: "", isRight: false }];
          }
        } else if (item.questionType === 8 && item.list) {
          caseRender(item.list);
        }
        // 开卷考试处理
        if (
          paperData.value.openExam == 1 &&
          (item.questionType == 4 || item.questionType == 5)
        ) {
          item.copyStrAnswer = JSON.parse(item.strAnswer);
        }
        // 选项是否打乱
        if (
          item.questionType == 1 ||
          item.questionType == 2 ||
          item.questionType == 3
        ) {
          isRandom && (item.options = shuffle(item.options));
        }
        quesObj.value.get(item.questionType).data.push(item);
      });
      quesObj.value.forEach(function (value, key) {
        value.totalScore = 0;
        for (let i = 0; i < value.data.length; i++) {
          value.totalScore += value.data[i].score;
        }
        if (String(value.totalScore).indexOf(".") > -1) {
          value.totalScore = value.totalScore.toFixed(1);
        }
      });
      if (paperData.value.remainTime == 0) {
        submit(4);
        return false;
      }
      // 删除没有题目题型的map
      Object.keys(questionTypeEnum).forEach((key) => {
        if (quesObj.value.get(Number(key)).data.length == 0) {
          quesObj.value.delete(Number(key));
        }
      });
      console.log(quesObj.value);
      countDown(paperData.value.remainTime * 1000);
    };

    // 时分秒计算
    const time = ref({
      h: "00",
      m: "00",
      s: "00",
    });
    const formatDuring = (sec) => {
      let hours = parseInt((sec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = parseInt((sec % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = (sec % (1000 * 60)) / 1000;
      time.value.h = hours < 10 ? "0" + hours : hours;
      time.value.m = minutes < 10 ? "0" + minutes : minutes;
      time.value.s = seconds < 10 ? "0" + seconds : seconds;
    };

    // 倒计时
    let countTimer = null;
    const countDown = (sec) => {
      autoTimer = setInterval(() => {
        autoSave();
      }, 30 * 1000);
      countTimer = setInterval(() => {
        sec -= 1000;
        state.examRemainTime = sec;
        formatDuring(sec);
        if (sec === 0) {
          clearInterval(countTimer);
          submit(4);
        }
      }, 1000);
    };

    // 清除定时器
    const clearTimer = () => {
      if (countTimer) {
        clearInterval(countTimer);
        countTimer = null;
      }
      if (autoTimer) {
        clearInterval(autoTimer);
        autoTimer = null;
      }
    };

    // 单选/判断
    const radioChange = (e, question) => {
      setTimeout(() => {
        question.options.map((item) => {
          if (item.id === question.userAnswer) {
            item.isRight = true;
          } else {
            item.isRight = false;
          }
        });
        question.answers = [question.userAnswer];
        toFinish();
      }, 0);
    };

    // 多选
    const checkboxChange = (e, question, options) => {
      options.isRight = !options.isRight;
      setTimeout(() => {
        question.answers = question.userAnswer;
        toFinish();
      }, 0);
    };

    // 填空
    const gapFilling = _.debounce((question, options) => {
      question.userAnswer = [];
      question.options.map((arr) => {
        if (arr.title.trim() === "") {
          arr.isRight = false;
        } else {
          question.userAnswer.push(arr.id);
          arr.isRight = true;
        }
      });
      question.answers = question.userAnswer;
      toFinish();
    }, 1000);

    // 问答
    const qAndA = _.debounce((question, options) => {
      question.userAnswer = [];
      if (question.options[0].title.trim() == "") {
        question.userAnswer = [];
        question.options[0].isRight = false;
      } else {
        question.userAnswer = [0];
        question.options[0].isRight = true;
      }
      question.answers = question.userAnswer;
      toFinish();
    }, 1000);

    // 进度计算
    const finished = ref(0);
    const toFinish = () => {
      ls.set(paperData.value.submitId, paperData.value);
      finished.value = 0;
      paperData.value.questions.forEach((item) => {
        if (item.questionType === 8) {
          item.list.forEach((item2) => {
            if (
              item2.answers.length ||
              (item2.questionType === 5 && item2.answerImg.length)
            ) {
              finished.value++;
            }
          });
        } else {
          if (
            item.answers.length ||
            (item.questionType === 5 && item.answerImg.length)
          ) {
            finished.value++;
          }
        }
      });
    };

    // 答题卡定位
    const scrollTo = (id) => {
      document.getElementById("que_" + id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    };

    /**
     * 切屏
     * 1.浏览器 tab 切换 最小化 visibilitychange
     * 2.其它切换窗口切换 window.onBlur
     * 3.页面关闭 beforeunload unLoad
     */
    const screenVisibility = () => {
      if (visProp) {
        let e = document[onShow()];
        canSwitchSubmit.value = false;
        if (e === "hidden") {
          initScreenTime();
        } else if (e === "visible") {
          clearScreenTime();
          submitSwitch();
        }
      }
    };
    const submitSwitch = () => {
      // 正在提交试卷 切屏不发送请求
      if (!loading.value) {
        return examSwitch(
          {
            ...state.params,
            paperId: paperData.value.paperId,
            submitId: paperData.value.submitId,
            planId: planId,
          },
          state.courseApi
        )
          .then((res) => {
            if (res.ret == 0) {
              if (res.data.max != -2) {
                paperData.value.useSwitchNum = res.data.max - res.data.remain;
                Modal.destroyAll();
                if (res.data.remain == 1) {
                  Modal.warning({
                    title: () =>
                      `${$t("exam.doexam_cut_screen_tip_2", [
                        1,
                        state.switchTimeStr,
                      ])} ${$t("exam.mandatory_submission")}`,
                    // 请注意，切屏次数还剩1次，再切屏将会强制交卷
                  });
                } else if (
                  res.data.remain == 0 ||
                  projectData.value.switchTime === 0
                ) {
                  // 切屏时间为0也为不允许切屏
                  submit(3);
                } else {
                  Modal.warning({
                    title: () =>
                      $t("exam.doexam_cut_screen_tip_2", [
                        res.data.remain,
                        state.switchTimeStr,
                      ]),
                  });
                  // 请注意，切屏次数还剩
                }
              }
              canSwitchSubmit.value = true;
            }
          })
          .finally(() => {
            setTimeout(() => {
              canSwitchSubmit.value = true;
            }, 400);
          });
      }
    };
    const windowBlur = _.throttle(() => {
      // 标签切换/最小化也会触发 blur -- 延迟执行 保证走 visibilitychange
      setTimeout(() => {
        if (!canSwitchSubmit.value) return;
        submitSwitch();
      }, 200);
      initScreenTime();
    });
    const windowFocus = _.throttle(() => {
      clearScreenTime();
    });
    const windosTime = reactive({
      beforeUnloadTime: 0,
      gapTime: 0,
    });
    const windowBeforeunload = () => {
      windosTime.beforeUnloadTime = new Date().getTime();
    };
    const windowUnload = async () => {
      windosTime.gapTime = new Date().getTime() - windosTime.beforeUnloadTime;
      // 判断是关闭还是刷新
      if (windosTime.gapTime <= 5) {
        await examSwitch(
          {
            ...state.params,
            paperId: paperData.value.paperId,
            submitId: paperData.value.submitId,
            planId: planId,
          },
          state.courseApi
        ).then(() => {});
      }
    };
    // 超过切屏时间强制交卷
    const screenTime = reactive({
      timer: null,
      total: 0,
    });
    const initScreenTime = () => {
      if (screenTime.timer || projectData.value.switchTime < 0) return;
      screenTime.timer = setInterval(() => {
        screenTime.total += 1;
        if (screenTime.total >= projectData.value.switchTime) {
          clearScreenTime();
          submit(3);
        }
      }, 1000);
    };
    const clearScreenTime = () => {
      clearInterval(screenTime.timer);
      screenTime.timer = null;
      screenTime.total = 0;
    };

    onMounted(() => {});

    let isNormal = false;
    onBeforeRouteLeave((to, from, next) => {
      if (!isNormal) {
        Modal.confirm({
          title: () => $t("XB_GiveUpContinueAnswerQuestions"),
          // 确定要放弃继续答题吗？
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            examSwitch(
              {
                ...state.params,
                paperId: paperData.value.paperId,
                submitId: paperData.value.submitId,
                planId: planId,
              },
              state.courseApi
            ).then((res) => {
              if (
                (res.data.max != -2 && res.data.remain == 0) ||
                projectData.value.switchTime === 0
              ) {
                submit(3, next());
              } else {
                autoSave();
                next();
              }
            });
          },
        });
      } else {
        next();
      }
    });

    onBeforeUnmount(() => {
      if (visProp) {
        let evtname = visProp.replace(/[H|h]idden/, "") + "visibilitychange";
        document.removeEventListener(evtname, screenVisibility);
      }
      clearTimer();
      if (!isDingding) {
        cancelFullscreen(document.querySelector("body"));
      }
      clearScreenTime();
    });

    onUnmounted(() => {
      window.removeEventListener("blur", windowBlur);
      window.removeEventListener("focus", windowFocus);
      window.removeEventListener("beforeunload", windowBeforeunload);
      window.removeEventListener("unload", windowUnload);
    });

    // 自动保存
    const autoSave = () => {
      Modal.destroyAll();
      paperData.value.submitType = 1;
      examAutoSave(paperData.value, state.courseApi);
    };

    // 暂存
    const stagedLoading = ref(false);
    const handleStaged = () => {
      Modal.confirm({
        title: $t("exam.whether_save_paper"),
        // 是否保存答卷
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          paperData.value.submitType = 1;
          paperData.value.planId = planId;
          stagedLoading.value = true;
          examAutoSave(paperData.value, state.courseApi)
            .then((res) => {
              if (res.ret === 0) {
                proxy.$message.success($t("Pub_Msg_SaveSuccess"));
                // 保存成功！
              } else {
                proxy.$message.success($t("Pub_Msg_SaveFail"));
                // 保存失败！
              }
            })
            .finally(() => {
              stagedLoading.value = false;
            });
        },
      });
    };

    const loading = ref(false);
    // 提交 2用户点交卷 3切屏次数达到后强制提交 4考试时间结束客户端强制提交 5考试时间结束服务器强制提交 7人脸识别不通过强制交卷
    const submit = (type, next) => {
      paperData.value.submitType = type;
      paperData.value.planId = planId;
      if (type == 2) {
        let allQueNum = document.querySelectorAll(".answer-chunk").length || 0;
        let checkedQueNum =
          document.querySelectorAll(".answer-chunk.checked").length || 0;
        let residueQueNum = allQueNum - checkedQueNum;
        Modal.confirm({
          title: () =>
            (residueQueNum
              ? $t("exam.unanswered_to_submit", [residueQueNum], 1)
              : "") + $t("exam.unanswered_to_submit", 2),
          // 还有 道题未答 确定提交答卷
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            loading.value = true;
            examSubmit(paperData.value, state.courseApi).then((res) => {
              if (res.ret == 0) {
                clearTimer();
                // 考试开启签名
                if ([1, 3].includes(projectData.value.openSign)) {
                  return signatureModalRef.value.show(
                    paperData.value.submitId,
                    courseId
                  );
                }
                backLatency(backFn);
              }
            });
          },
          onCancel() {
            loading.value = false;
          },
        });
      } else {
        loading.value = true;
        examSubmit(paperData.value, state.courseApi).then((res) => {
          if (res.ret == 0) {
            clearTimer();
            if (type == 3) {
              proxy.$message.warning($t("CM_CutNumber"));
              // 切屏次数超过限制,将强制交卷!
              // 考试开启签名
              if ([1, 3].includes(projectData.value.openSign)) {
                return signatureModalRef.value.show(paperData.value.submitId);
              }
              next && backLatency(next);
            } else if (type == 4) {
              proxy.$message.warning($t("exam.exam_time_has_end"));
              // 考试时间已结束,正在提交试卷
            } else if (type == 7) {
              // 人脸识别不通过，将强制交卷
              proxy.$message.warning($t("FaceMatchErrorTip2"));
              next && backLatency(next);
            }
            // 考试开启签名
            if ([1, 3].includes(projectData.value.openSign)) {
              return signatureModalRef.value.show(paperData.value.submitId);
            }
            backLatency(backFn);
          }
        });
      }
    };

    const backFn = () => {
      ls.remove(paperData.value.submitId);
      isNormal = true;
      if (source === "map") {
        // paperData.value.submitType == 2 &&
        // proxy.$message.success($t("exam.exam_map_submit"));
        // 您已成功提交了答卷,将返回学习地图
        if (isDingding) {
          location.href = location.origin + `/map/detail?id=${taskId}`;
        } else {
          router.replace({
            path: "/map/detail",
            query: {
              id: taskId,
            },
          });
        }
      } else {
        // paperData.value.submitType == 2 &&
        //   proxy.$message.success($t("LB_Exam_HandPaperReturnExam"));
        // 您已成功提交了答卷,将返回我的考试
        if (isDingding) {
          const url =
            queryData.taskType == 23 ? `&reexamId=${reexamId}&taskType=23` : "";
          if (courseId) {
            location.href =
              location.origin +
              `/exam/detail?courseId=${courseId}&taskId=${taskId}&coursePlusId=${detailId}&planId=${planId}&isIdp=${isIdp}` +
              url;
          } else {
            location.href =
              location.origin +
              `/exam/detail?id=${taskId}&did=${detailId}&planId=${planId}&isIdp=${isIdp}` +
              url;
          }
        } else {
          let query = {
            id: taskId,
            did: detailId,
            isIdp: isIdp,
          };
          if (courseId) {
            query = {
              ...state.params,
            };
          }
          if (queryData.taskType == 23) {
            query.reexamId = reexamId;
            query.taskType = 23;
          }
          if (planId) {
            query.planId = planId;
          }
          router.replace({
            path: "/exam/detail",
            query: query,
          });
        }
      }
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const handleBeforeUpload = (file) => {
      if (!file.size) {
        proxy.$message.error($t("upload.file_siez_cannot_be"));
        // 文件大小不能为0
        return false;
      }
      const sizeFlag = file.size < 10 * 1024 * 1024;
      if (!sizeFlag) {
        proxy.$message.error($t("upload.max_size_tip", ["10MB!"]));
        // 文件大小不能超过10MB
        return sizeFlag;
      }
    };

    const upLoading = ref(false);
    const handleUploadChange = (info, item) => {
      if (info.file.status === "uploading") {
        upLoading.value = true;
      }
      if (info.file.status === "done") {
        upLoading.value = false;
        let res = info.file.response;
        if (res.ret == 0) {
          let url = res.data.exist
            ? process.env.VUE_APP_RES_URL + res.data.file
            : getCdnUrl(res.data.file, "temp");
          item.answerImg.push(res.data.file);
          item.answerImg2.push(url);
          // item.options[0].isRight = true;
          toFinish();
        }
      }
    };

    const delPic = (item, index) => {
      item.answerImg.splice(index, 1);
      item.answerImg2.splice(index, 1);
      // if (item.answerImg.length == 0 && item.options[0].title.trim() == "") {
      //   item.options[0].isRight = false;
      // }
      toFinish();
    };

    // 考试签名
    const signatureModalRef = ref(null);
    const completeSignature = () => {
      setTimeout(() => {
        proxy.$message.destroy();
        backFn();
      }, 1000);
    };

    // 人脸识别
    const faceMatchRef = ref(null);
    const setLearningFace = () => {
      if (!state.lastCount) return false;
      if (state.faceSet && state.faceSet.center) {
        // 取平均时间的中间
        let random = 0;
        if (state.faceAverageTime) {
          random = state.faceAverageTime;
        } else {
          state.faceAverageTime =
            Math.ceil(paperData.value.remainTime / state.lastCount) || 2 * 60;
          random = Math.ceil(state.faceAverageTime / 2);
        }
        console.log(random, "--random--");
        setTimeout(() => {
          // 提交试卷 不人脸识别
          if (!loading.value) {
            state.lastCount -= 1;
            faceMatchRef.value.show(3);
            // 暂停
            stopExamFn(1);
          }
        }, random * 1000);
      }
    };
    const handleFace = (data) => {
      if (data.code == 1) {
        // 恢复
        stopExamFn();
        setLearningFace();
      } else {
        submit(7);
      }
    };

    // 返回后 成绩不能马上出现 延时返回
    const latency = reactive({
      s: 5,
      timer: null,
    });
    const backLatency = (fn) => {
      if (latency.timer) return;
      latency.timer = setInterval(() => {
        if (latency.s === 0) {
          clearInterval(latency.timer);
          latency.latency = null;
          fn && fn();
        }
        latency.s -= 1;
      }, 1000);
    };

    // 考试暂停
    const stopExamFn = async (stop = 0) => {
      const p = {
        isStop: stop, //任务类型 1暂停倒计时 0开始倒计时
        submitId: paperData.value.submitId, //提交编号
        taskType: courseId ? 2 : 1, //任务类型 1 考试 2课程考试
        timeSurplus: state.examRemainTime / 1000, // 剩余时长(秒)
      };
      const res = await stopExam(p);
      if (res.ret !== 0) {
        proxy.$message.error(res.msg);
      }
      if (stop === 1) {
        clearTimer();
      } else {
        countDown(state.examRemainTime);
      }
    };

    return {
      ...toRefs(state),
      questionTypeEnum,
      uploadUrl,
      getHeader,
      toChinesNum,
      taskId,
      showTips,
      currentQuestion,
      qusetionTotal,
      viewTips,
      letterOpts,
      projectData,
      paperData,
      quesObj,
      radioChange,
      checkboxChange,
      gapFilling,
      qAndA,
      finished,
      time,
      scrollTo,
      submit,
      StartExam,
      platformConfig,
      getResourceType,
      loading,
      handleStaged,
      stagedLoading,
      noUploadImg,
      handleBeforeUpload,
      handleUploadChange,
      delPic,
      signatureModalRef,
      completeSignature,
      faceMatchRef,
      handleFace,
      latency,
    };
  },
};
</script>

<style lang="less">
:fullscreen #app {
  height: 100%;
  overflow-y: auto;
}
</style>
<style lang="less" scoped>
.exam-no-select {
  user-select: none;
}
.head {
  text-align: center;
  padding: 36px 0;
  background-color: #fff;
  h1 {
    color: #333;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    .mixinEllipsis(50px);
  }
  p {
    color: #555;
    font-size: 14px;
    margin: 0;
    line-height: 18px;
    padding: 2px 0;
    span {
      padding: 0 12px;
      border-right: 1px solid #bbbbbb;
      &:last-child {
        border-right: none;
      }
    }
  }
}
.main {
  background-color: @color-page-light;
  padding-bottom: 80px;
  .content {
    .mixinWrap(1230px);
    .mixinFlex(space-between);
    position: relative;
  }
  .questions {
    width: 894px;
    .headline {
      line-height: 68px;
      color: #202020;
      .classify {
        font-size: 20px;
      }
      .score {
        font-size: 16px;
      }
    }
    &-item {
      padding: 24px 24px 8px 24px;
      background-color: #fff;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &-head {
        margin-bottom: 16px;
        .mixinFlex(space-between; center);
        .type-title {
          color: #e10808;
          font-size: 18px;
          line-height: 30px;
          span {
            color: #202020;
          }
        }
        .questions-item-more {
          .tips {
            cursor: pointer;
            margin-right: 20px;
            font-size: 16px;
            vertical-align: 1px;
            color: #999;
          }
          .mask {
            cursor: pointer;
            user-select: none;
            font-size: 20px;
            width: 30px;
            text-align: center;
            .gray {
              color: #999;
            }
            .highlight {
              color: #f65464;
            }
          }
        }
      }
      .title {
        font-size: 18px;
        line-height: 1.7;
        color: #202020;
        margin: 16px 0;
      }
      .opt-checkbox,
      .opt-radio {
        .mixinFlex(flex-start; center);
        white-space: normal !important;
        ::v-deep(.ant-checkbox),
        ::v-deep(.ant-radio) {
          display: none;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }
        .opt-item {
          .mixinFlex(flex-start; flex-start);
          font-size: 16px;
          margin-bottom: 16px;
          .letter {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            .mixinFlex(center; center);
            margin-right: 20px;
            border: 1px solid #dddddd;
          }
          .info {
            width: calc(100% - 48px);
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.ant-checkbox-wrapper-checked,
        &.ant-radio-wrapper-checked {
          .letter {
            border-color: #266fff;
            color: #fff;
            background-color: #266fff;
          }
          .text {
            color: #266fff;
          }
        }
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
      .opt-textarea-box {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        .resize-h {
          display: inline-block;
          width: 220px;
          min-height: 30px;
          visibility: hidden;
          word-break: break-all;
          border-bottom: 1px solid #202020;
          padding: 1px 4px;
        }
        .opt-textarea {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          min-height: 30px;
          border: none;
          border-bottom: 1px solid #202020;
          padding: 1px 4px;
          text-align: center;
          resize: none;
          overflow-y: hidden;
          border-radius: 0;
        }
      }
      .opt-input {
        margin-left: 10px;
        border: none;
        border-bottom: 1px solid #202020;
        padding: 1px 4px;
        text-align: center;
      }
      .opt-textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        min-height: 120px;
        margin-top: 4px;
        font-size: 16px;
      }
      .opt-pics {
        margin-top: 12px;
        &-item {
          display: inline-block;
          width: 105px;
          height: 105px;
          margin-right: 10px;
          border-radius: 4px;
          position: relative;
          &.ed {
            width: 105px;
            height: 105px;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            font-size: 0;
            position: relative;
            user-select: none;
            display: inline-flex;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
          &.add {
            border: 2px dashed #ddd;
            display: inline-flex;
            vertical-align: top;
            cursor: pointer;
            .up-input {
              width: 105px;
              height: 105px;
              .mixinFlex(center; center; column);
              color: #666;
              &-text {
                margin-top: 6px;
              }
            }
          }
          &-close {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            border-radius: 0 0 0 4px;
            cursor: pointer;
            .mixinFlex(center; center);
            .icon {
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }
    }
    .case-box {
      padding: 12px;
      background-color: #fafafa;
      &-head {
        .title {
          font-size: 18px;
          line-height: 1.7;
          color: #202020;
          margin: 0 0 16px;
        }
      }
    }
  }
  .sidebar {
    width: 310px;
    // width: 282px;
    padding-top: 24px;
    position: relative;
    &-content {
      position: sticky;
      top: 24px;
    }
    .time {
      background-color: #fff;
      padding: 14px;
      margin-bottom: 16px;
      text-align: center;
      h5 {
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-bottom: 5px;
        font-weight: 500;
      }
      .down {
        font-size: 36px;
        color: #f65464;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 42px;
        font-family: DINAlternate-Bold, DINAlternate;
      }
    }
    .sheet {
      background-color: #fff;
      padding: 20px 16px;
      margin-top: 20px;
      h5 {
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
      }
      .progress {
        padding: 20px 0;
        .mixinFlex(space-between; center);
        .left {
          width: 200px;
        }
        .right {
          font-size: 18px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #333333;
          line-height: 20px;
          letter-spacing: 1px;
          margin-left: 10px;
        }
      }
      .label {
        padding-bottom: 20px;
        font-size: 16px;
        color: #333;
        .mixinFlex(space-around);
        .item {
          .mixinFlex(flex-start; center);
          span {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 2px;
          }
          &:nth-child(1) {
            span {
              background-color: #447dff;
            }
          }
          &:nth-child(2) {
            span {
              border: 1px solid #999999;
            }
          }
          &:nth-child(3) {
            span {
              border: 1px solid #999999;
              position: relative;
              overflow: hidden;
              &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
                background-color: #f65464;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      .scrollbar();
      .sheet-list {
        height: calc(100vh - 420px);
        max-height: 400px;
        overflow-y: auto;
        .type-path {
          .classify {
            font-size: 16px;
            color: #333;
            line-height: 26px;
            margin-bottom: 8px;
          }
          .list {
            .item {
              float: left;
              width: 36px;
              height: 40px;
              overflow: hidden;
              border-radius: 2px;
              border: 1px solid #999999;
              cursor: pointer;
              margin-right: 14px;
              margin-bottom: 14px;
              color: #999999;
              font-size: 18px;
              position: relative;
              .mixinFlex(center; center);
              &:nth-child(5n) {
                margin-right: 0;
              }
              &.checked {
                border-color: #447dff;
                color: #447dff;
              }
              &.isMask {
                &::after {
                  content: "";
                  position: absolute;
                  bottom: -6px;
                  right: -6px;
                  width: 12px;
                  height: 12px;
                  background-color: #f65464;
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
      .btn {
        .mixinFlex(space-between, center);
        padding-top: 20px;
        .ant-btn {
          width: 45%;
          height: 48px;
          font-size: 16px;
          background-color: #447dff;
          border-radius: 4px;
          border: 0;
        }
        .ant-btn-dangerous {
          background-color: #ff7900;
        }
      }
    }
  }
}
.right-answer {
  padding: 10px;
  background: #f5f5f5;
  margin-top: 12px;
}
.tips-modal,
.right-answer {
  .answer {
    font-size: 16px;
    font-weight: bold;
    .mr-10 {
      margin-right: 10px;
    }
  }
  .analysis {
    color: #666666;
    font-size: 15px;
  }
}
.paper-spin {
  ::v-deep(.ant-spin) {
    position: fixed !important;
    top: 20% !important;
  }
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 34px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 80px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
</style>
